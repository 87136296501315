import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DeliveryPeopleService } from "../../service/DeliveryPeopleService";
import { ExpeditionsService } from "../../service/ExpeditionsService";
import { DataTable } from "primereact/datatable";
import { Checkbox } from 'primereact/checkbox';

import { Column } from "primereact/column";
import { DateTime } from "luxon";
import ExpeditionDetails from "../expeditions/ExpeditionDetails";
import { DeliverySessionsService } from "../../service/DeliverySessionService";
import { Toast } from "primereact/toast";

const AddDeliverySession = ({ setRefetchData }) => {
  const toast = useRef(null);
  const deliveryMenService = new DeliveryPeopleService();
  const expeditionService = new ExpeditionsService();
  const deliverySessionsService = new DeliverySessionsService()
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const openDialog = () => setDialogVisibility(true);
  const closeDialog = () => setDialogVisibility(false);
  const [deliveryMen, setDeliveryMen] = useState(null);
  const [loadingDeliveryMen, setLoadingDeliveryMen] = useState(false);
  const [selectedDeliveryMen, setSelectedDeliveryMan] = useState(null);
  const [expeditions, setExpeditions] = useState(null);
  const [loadingExpedtions, setLoadingExpedtions] = useState(false);
  const [selectedExpeditions, setSelectedExpeditions] = useState([]);

  useEffect(() => {
    async function getDeliveryMen() {
      setLoadingDeliveryMen(true);
      const queryParams = `current_page=1&limit=50&total_skip=0`;
      const response = await deliveryMenService.getAll(queryParams);
      if (response && response.data) {
        setDeliveryMen(response.data.records);
      } else {
        console.log(response.error);
      }
      setLoadingDeliveryMen(false);
    }
    if (dialogVisibility) {
      getDeliveryMen();
    }
    return () => {
      setSelectedDeliveryMan(null);
      setSelectedExpeditions([]);
    };
  }, [dialogVisibility]);

  //ON SELECTED DELIVERY MAN CHANGED
  useEffect(() => {
    async function getExpeditionsDeliveryMen() {
      setLoadingExpedtions(true);
      const queryParams = `current_page=1&limit=50&total_skip=0&sortField=createdAt&sortOrder=-1&status=pending`;
      const response = await expeditionService.getAll(queryParams);
      if (response && response.data) {
        setExpeditions(response.data.records);
      } else {
        console.log(response.error);
      }
      setLoadingExpedtions(false);
    }
    if (selectedDeliveryMen) {
      getExpeditionsDeliveryMen();
    }
    return () => {
      setExpeditions([]);
      setSelectedExpeditions([]);
    };
  }, [selectedDeliveryMen]);

  const handleValidate = () => {
    let disabled = false;
    if (
      (selectedDeliveryMen && selectedExpeditions.length === 0) ||
      !selectedDeliveryMen
    ) {
      disabled = true;
    }
    return disabled;
  };

  const onValidate = async () => {
    const payload = {
      expeditionsIds: selectedExpeditions.map((selectedExp) => selectedExp._id),
      deliveryMan: selectedDeliveryMen
    }
    const response = await deliverySessionsService.create(payload)
    if(response.data){
      setDialogVisibility(false)
      setRefetchData(true)
    } else {
      console.log(response.error);
      toast
        .current
        .show({
          severity:'error', 
          summary: 'Erreur', 
          detail:"erreur lors de la création d'une session de livraison", 
          life: 2000
        });
    }
  };

  const onRowClick = (event) => {
    console.log("row clicked")
    console.log(event.data)
  }

  const onSelectedExpeditionsChange = (event) => {
    console.log("row selected")
    setSelectedExpeditions(event.value)
  }

  const ExpeditionsList = () => {

    const bodyTemplateStatus = (val) => {
      let severity, text;
      if (val.status === "pending") {
        severity = "#FAAE42";
        text = "En attente";
      } else if (val.status === "completed") {
        severity = "#05B171";
        text = "Complétée";
      }
      return (
        <div
          style={{
            backgroundColor: severity,
            borderRadius: 12,
            display: "inline-block",
          }}
          className="pr-2 pl-2"
        >
          <p
            className="text-sm font-medium lowercase"
            style={{ color: "#fff" }}
          >
            {text}
          </p>
        </div>
      );
    };

    const actionsBodyTemplate = (rowData) => {
      return (
        <div className="actions flex">
          <ExpeditionDetails
            orders={rowData.order_ids}
          />
        </div>
  
      )
    }

    return (
      <>
        <Toast ref={toast}/>
        <DataTable
          onRowClick={onRowClick}
          selectionMode="checkbox"
          onSelectionChange={onSelectedExpeditionsChange}
          selection={selectedExpeditions}
          loading={loadingExpedtions}
          value={expeditions}
          dataKey="_id"
          size="small"
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="aucune expédition en attente disponible"
        >
          <Column 
            selectionMode="multiple" 
            headerStyle={{ width: "3rem" }} 
          />
          <Column field="name" header="Nom" />
          <Column
            field="createdAt"
            header="Date"
            body={(val) =>
              DateTime
                .fromISO(val.createdAt)
                .setZone("Africa/Casablanca")
                .toFormat("dd-MM-yy HH:mm")
            }
          />
          <Column field="status" header="statut" body={bodyTemplateStatus} />
          <Column 
            header="actions"
            body={actionsBodyTemplate}
          />
        </DataTable>
      </>
    );
  };

  const footer = () => {
    return (
      <div className="flex-ends">
        <Button
          disabled={handleValidate()}
          className="p-button-sm"
          label="valider"
          onClick={onValidate}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        label="nouvelle séance"
        icon="pi pi-plus"
        className="mr-2"
        onClick={openDialog}
      />
      <Dialog
        draggable={false}
        visible={dialogVisibility}
        header="Ajouter séance de livraison"
        modal
        className="p-fluid"
        onHide={closeDialog}
        footer={footer}
      >
        <div>
          <p className="font-bold">livreurs</p>
          <Dropdown
            className="w-full md:w-14rem mb-4"
            filter
            showClear
            disabled={loadingDeliveryMen}
            value={selectedDeliveryMen}
            onChange={(e) => setSelectedDeliveryMan(e.value)}
            options={deliveryMen}
            optionLabel="fullName"
            optionValue="_id"
            placeholder={
              loadingDeliveryMen ? "Chargement..." : "choisissez un livreur"
            }
          />
        </div>
        {selectedDeliveryMen && <ExpeditionsList />}
      </Dialog>
    </>
  );
};

export default AddDeliverySession;
