import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { BrandsService } from '../service/BrandsService';
import { CategoryService } from '../service/CategoryService';
import { InputSwitch } from 'primereact/inputswitch';
//components
import AddBrand from '../components/brands/AddBrand';
import EditBrand from '../components/brands/EditBrand';
import ImageTemplate from '../components/orders/ImageTemplate';
import RemiseModal from '../components/brands/RemiseModal';
import NoData from '../components/NoData';

const Brands = () => {
    
    const subCategoryService = new BrandsService();
    const categoryService = new CategoryService();
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState(null);
    const [remiseDialog, setRemiseDialog] = useState(false)
    const [selectedUnderCategory, setSelectedUnderCategory]= useState(null) //used for remise
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dt = useRef(null);

    const getCategories=async()=>{
        const response = await categoryService.getCategories()
        let _categories = []
        if(response.data){
            response.data.map(category => {
                _categories.push(
                    { nameCategory: category.nameCategory, _id: category._id }
                )
            })
            setCategories(_categories)
        } else {
            console.log(response.error)
        }
    }

    async function getData(){
        setLoading(true)
        const response = await subCategoryService.getSubCategories()
        if(response.data){
            setBrands(response.data)
            getCategories()
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }


    useEffect(() => {
        getData()
    }, []);

    // CREATE SUB CATEGORY
    const createSubCategory = async (data) => {
        const response = await subCategoryService.createSubCategory(data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    // UPDATE SUB CATEGORY
    const updateSubCategory = async (id,data) => {
        const response = await subCategoryService.updateSubCategory(id,data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    

    // UPDATE SUB CATEGORY STATUS
    const updateStatusSubCategoty = async(id,status) => {
        const response = await subCategoryService.updateSubCategory(id,{active:status})
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche" />
                </span>
            </div>
        );
    };

    // STATUS BODY TEMPLATE
    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.active} 
            onChange={(e) => 
                updateStatusSubCategoty(rowData._id,e.value)
            } />
        )
    }

    const categoryBodyTemplate = (rowData) => {
        let _categories = ``
        rowData.category.map(({ nameCategory }, index) => {
            if((index + 1) == rowData.category.length)  _categories+= nameCategory
            else _categories+= `${nameCategory} - `
        })

        return <p>{_categories}</p>
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
               <EditBrand updateSubCategory={updateSubCategory} categories={categories} rowData={rowData}/>
               <Button icon="pi pi-percentage" rounded outlined className="mr-2" onClick={() => {
                    setSelectedUnderCategory(rowData)
                    setRemiseDialog(true)}
                }/>
            </div>
        );
    }

    

    const imageTemplate=(rowData)=>{
        return (
            <>
            {
                rowData.photo.url !== null
                ? <img src={rowData.photo.url} alt={rowData.photo.url} className="shadow-2" width="50" />
                : <b style={{color:'#f00'}}>aucune image</b>
            }
            </>   
        )
    }

    const header = renderHeader()

    return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>sous-catégorie ou marque</p>
                    </div>
                    <AddBrand categories={categories} createSubCategory={createSubCategory} />
                </div>

                <div className='card'>
                    <DataTable size='small' 
                    header={header}
                    stripedRows 
                    ref={dt} 
                    value={brands} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    paginator
                    dataKey="id" 
                    globalFilter={globalFilterValue} 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} marques"
                    emptyMessage={<NoData label='Aucune marque disponible'/>}>
                            <Column sortable field="nameUnderCategory" header="marque"></Column>
                            <Column sortable header="catégorie" body={categoryBodyTemplate}></Column>
                            <Column field="img" header="image" body={(rowData)=><ImageTemplate photos={[rowData?.photo.url]}/>}></Column>
                            <Column sortable field="active" header="status" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>  
                </div>
            </div>
            {selectedUnderCategory != null &&
                <RemiseModal remiseDialog={remiseDialog} setRemiseDialog={setRemiseDialog} selectedUnderCategory={selectedUnderCategory} />
            }
            {/* MODAL REMISE */}
        </div>
  )
}

export default Brands